import PropTypes from "prop-types"
import React from "react"
import { Box, Flex } from "@chakra-ui/react"

const DetailPageWrapper = props => {
  return (
    <Flex
      pt="140px"
      height="100%"
      minHeight="100vh"
      direction={["column-reverse", "column-reverse", "row"]}
      justify="flex-end"
      align="flex-start"
    >
      {/* ... Pagination Spacing */}
      <Box w="60px"></Box>
      {/* ContentArea */}
      <Flex
        w={["100%", "100%", "15%"]}
        justify="center"
        align={props.isSlider ? "flex-start" : "center"}
        p={["8px", "16px"]}
      >
        {/* Left Side */}
        {props.leftContent}
      </Flex>
      <Flex
        w={["100%", "100%", "85%"]}
        align="flex-end"
        justify="flex-end"
        padding="16px"
        pr="0px"
        mb="64px"
      >
        <Flex
          p={["24px", "32px", "64px", "64px", "84px"]}
          pt={["40px", "48px", "64px", "64px", "84px"]}
          width="100%"
          position="relative"
          background="white"
          borderLeftRadius="30px"
          align="center"
          justify="center"
          maxWidth="1920px"
          // shadow="subtle"
          className="elevation_sm"
        >
          <Box maxWidth="1300px" width="100%">
            {props.children}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

DetailPageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DetailPageWrapper
